@import "../css/variables.module";

.excalidraw {
  --ExcTextField--color: var(--color-gray-80);
  --ExcTextField--label-color: var(--color-gray-80);
  --ExcTextField--background: white;
  --ExcTextField--readonly--background: var(--color-gray-10);
  --ExcTextField--readonly--color: var(--color-gray-80);
  --ExcTextField--border: var(--color-gray-40);
  --ExcTextField--border-hover: var(--color-gray-50);
  --ExcTextField--placeholder: var(--color-gray-40);

  &.theme--dark {
    --ExcTextField--color: var(--color-gray-10);
    --ExcTextField--label-color: var(--color-gray-20);
    --ExcTextField--background: var(--color-gray-85);
    --ExcTextField--readonly--background: var(--color-gray-80);
    --ExcTextField--readonly--color: var(--color-gray-40);
    --ExcTextField--border: var(--color-gray-70);
    --ExcTextField--border-hover: var(--color-gray-60);
    --ExcTextField--placeholder: var(--color-gray-80);
  }

  .ExcTextField {
    &--fullWidth {
      width: 100%;
      flex-grow: 1;
    }

    &__label {
      font-family: "Assistant";
      font-style: normal;
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 150%;

      color: var(--ExcTextField--label-color);

      margin-bottom: 0.25rem;
      user-select: none;
    }

    &__input {
      box-sizing: border-box;

      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 1rem;

      height: 3rem;

      background: var(--ExcTextField--background);
      border: 1px solid var(--ExcTextField--border);
      border-radius: 0.5rem;

      &:not(&--readonly) {
        &:hover {
          border-color: var(--ExcTextField--border-hover);
        }

        &:active,
        &:focus-within {
          border-color: var(--color-primary);
        }
      }

      & input {
        display: flex;
        align-items: center;

        border: none;
        outline: none;
        padding: 0;
        margin: 0;

        height: 1.5rem;

        color: var(--ExcTextField--color);

        font-family: "Assistant";
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 150%;
        text-overflow: ellipsis;

        background: transparent;

        width: 100%;

        &::placeholder {
          color: var(--ExcTextField--placeholder);
        }

        &:not(:focus) {
          &:hover {
            background-color: initial;
          }
        }

        &:focus {
          outline: initial;
          box-shadow: initial;
        }
      }

      &--readonly {
        background: var(--ExcTextField--readonly--background);
        border-color: transparent;

        & input {
          color: var(--ExcTextField--readonly--color);
        }
      }
    }
  }
}
