@import "../../css/variables.module";

.excalidraw {
  .RoomDialog {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    @include isMobile {
      height: calc(100vh - 5rem);
    }

    &__popover {
      @keyframes RoomDialog__popover__scaleIn {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }

      box-sizing: border-box;
      z-index: 100;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
      padding: 0.125rem 0.5rem;
      gap: 0.125rem;

      height: 1.125rem;

      border: none;
      border-radius: 0.6875rem;

      font-family: "Assistant";
      font-style: normal;
      font-weight: 600;
      font-size: 0.75rem;
      line-height: 110%;

      background: var(--color-success-lighter);
      color: var(--color-success);

      & > svg {
        width: 0.875rem;
        height: 0.875rem;
      }

      transform-origin: var(--radix-popover-content-transform-origin);
      animation: RoomDialog__popover__scaleIn 150ms ease-out;
    }

    &__inactive {
      font-family: "Assistant";

      &__illustration {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;

        & svg {
          filter: var(--theme-filter);
        }
      }

      &__header {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;

        font-weight: 700;
        font-size: 1.3125rem;
        line-height: 130%;

        color: var(--color-primary);
      }

      &__description {
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 150%;

        text-align: center;

        color: var(--text-primary-color);

        & strong {
          display: block;
          font-weight: 700;
        }
      }

      &__start_session {
        display: flex;

        align-items: center;
        justify-content: center;
      }
    }

    &__active {
      &__share {
        display: none !important;

        @include isMobile {
          display: flex !important;
        }
      }

      &__header {
        margin: 0;
      }

      &__linkRow {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        gap: 0.75rem;
      }

      &__description {
        border-top: 1px solid var(--color-gray-20);

        padding: 0.5rem 0.5rem 0;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 150%;

        & p {
          margin: 0;
        }

        & p + p {
          margin-top: 1em;
        }
      }

      &__actions {
        display: flex;
        justify-content: center;
      }
    }
  }
}
