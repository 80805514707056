@import "../css/variables.module";

.excalidraw {
  .ExcButton {
    &--color-primary {
      color: var(--input-bg-color);

      --accent-color: var(--color-primary);
      --accent-color-hover: var(--color-primary-darker);
      --accent-color-active: var(--color-primary-darkest);
    }

    &--color-danger {
      color: var(--input-bg-color);

      --accent-color: var(--color-danger);
      --accent-color-hover: #d65550;
      --accent-color-active: #d1413c;
    }

    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    flex-wrap: nowrap;

    border-radius: 0.5rem;

    font-family: "Assistant";

    user-select: none;

    transition: all 150ms ease-out;

    &--size-large {
      font-weight: 400;
      font-size: 0.875rem;
      height: 3rem;
      padding: 0.5rem 1.5rem;
      gap: 0.75rem;

      letter-spacing: 0.4px;
    }

    &--size-medium {
      font-weight: 600;
      font-size: 0.75rem;
      height: 2.5rem;
      padding: 0.5rem 1rem;
      gap: 0.5rem;

      letter-spacing: normal;
    }

    &--variant-filled {
      background: var(--accent-color);
      border: 1px solid transparent;

      &:hover {
        background: var(--accent-color-hover);
      }

      &:active {
        background: var(--accent-color-active);
      }
    }

    &--variant-outlined,
    &--variant-icon {
      border: 1px solid var(--accent-color);
      color: var(--accent-color);
      background: transparent;

      &:hover {
        border: 1px solid var(--accent-color-hover);
        color: var(--accent-color-hover);
      }

      &:active {
        border: 1px solid var(--accent-color-active);
        color: var(--accent-color-active);
      }
    }

    &--variant-icon {
      padding: 0.5rem 0.75rem;
      width: 3rem;
    }

    &__icon {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
}
