@import "../../css/variables.module";

.excalidraw {
  .dropdown-menu {
    position: absolute;
    top: 100%;
    margin-top: 0.25rem;

    &--mobile {
      bottom: 55px;
      top: auto;
      left: 0;
      width: 100%;
      row-gap: 0.75rem;

      .dropdown-menu-container {
        padding: 8px 8px;
        box-sizing: border-box;
        background-color: var(--island-bg-color);
        box-shadow: var(--shadow-island);
        border-radius: var(--border-radius-lg);
        position: relative;
        transition: box-shadow 0.5s ease-in-out;

        &.zen-mode {
          box-shadow: none;
        }
      }
    }

    .dropdown-menu-container {
      background-color: #fff !important;
      max-height: calc(100vh - 150px);
      overflow-y: auto;
      --gap: 2;
    }

    .dropdown-menu-item-base {
      display: flex;
      padding: 0 0.625rem;
      column-gap: 0.625rem;
      font-size: 0.875rem;
      color: var(--color-gray-100);
      width: 100%;
      box-sizing: border-box;
      font-weight: normal;
      font-family: inherit;
    }

    .dropdown-menu-item {
      background-color: transparent;
      border: 0;
      align-items: center;
      height: 2rem;
      cursor: pointer;
      border-radius: var(--border-radius-md);

      @media screen and (min-width: 1921px) {
        height: 2.25rem;
      }

      &__text {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      &__shortcut {
        margin-inline-start: auto;
        opacity: 0.5;
      }

      &:hover {
        background-color: var(--button-hover-bg);
        text-decoration: none;
      }

      svg {
        width: 1rem;
        height: 1rem;
        display: block;
      }
    }

    .dropdown-menu-item-custom {
      margin-top: 0.5rem;
    }

    .dropdown-menu-group-title {
      font-size: 14px;
      text-align: left;
      margin: 10px 0;
      font-weight: 500;
    }
  }
  &.theme--dark {
    .dropdown-menu-item {
      color: var(--color-gray-40);
    }

    .dropdown-menu-container {
      background-color: var(--color-gray-90) !important;
    }
  }

  .dropdown-menu-button {
    @include outlineButtonStyles;
    background-color: var(--island-bg-color);
    width: var(--lg-button-size);
    height: var(--lg-button-size);

    svg {
      width: var(--lg-icon-size);
      height: var(--lg-icon-size);
    }

    &--mobile {
      border: none;
      margin: 0;
      padding: 0;
      width: var(--default-button-size);
      height: var(--default-button-size);
    }
  }
}
