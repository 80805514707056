.excalidraw {
  .excalidraw-eye-dropper-container,
  .excalidraw-eye-dropper-backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    touch-action: none;
  }

  .excalidraw-eye-dropper-container {
    pointer-events: none;
  }

  .excalidraw-eye-dropper-backdrop {
    pointer-events: all;
  }

  .excalidraw-eye-dropper-preview {
    pointer-events: none;
    width: 3rem;
    height: 3rem;
    position: fixed;
    z-index: 999999;
    border-radius: 1rem;
    border: 1px solid var(--default-border-color);
    filter: var(--theme-filter);
  }

  .excalidraw-eye-dropper-trigger {
    width: 1.25rem;
    height: 1.25rem;
    cursor: pointer;
    padding: 4px;
    margin-right: -4px;
    margin-left: -2px;
    border-radius: 0.5rem;
    color: var(--icon-fill-color);

    &:hover {
      background: var(--button-hover-bg);
    }
    &.selected {
      color: var(--color-primary);
      background: var(--color-primary-light);
    }
  }
}
