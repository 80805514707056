@import "../css/variables.module";

.excalidraw {
  &.excalidraw-modal-container {
    position: absolute;
    z-index: 10;
  }

  .Modal {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;
    padding: calc(var(--space-factor) * 10);

    .Island {
      padding: 2.5rem !important;
    }
  }

  .Modal__background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-color: rgba(#121212, 0.2);

    animation: Modal__background__fade-in 0.125s linear forwards;
  }

  .Modal__content {
    position: relative;
    z-index: 2;
    width: 100%;
    max-width: var(--max-width);
    max-height: 100%;

    opacity: 0;
    transform: translateY(10px);
    animation: Modal__content_fade-in 0.1s ease-out 0.05s forwards;
    position: relative;
    overflow-y: auto;

    // for modals, reset blurry bg
    background: var(--island-bg-color);

    border: 1px solid var(--dialog-border-color);
    box-shadow: var(--modal-shadow);
    border-radius: 6px;
    box-sizing: border-box;

    &:focus {
      outline: none;
    }

    @include isMobile {
      max-width: 100%;
      border: 0;
      border-radius: 0;
    }
  }

  @keyframes Modal__background__fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes Modal__content_fade-in {
    from {
      opacity: 0;
      transform: scale(0.9);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  .Modal__close {
    color: var(--icon-fill-color);
    margin: 0;
    padding: 0.375rem;
    position: absolute;
    top: 1rem;
    right: 1rem;
    border: 0;
    background-color: transparent;
    line-height: 0;
    cursor: pointer;

    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  @include isMobile {
    .Modal {
      padding: 0;
    }

    .Modal__content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}
